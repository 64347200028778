import React, { useEffect, useState } from "react";
import "./styles.scss";
import FilterComp from "../filtercomp";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { IS_MARQUE_DRAWER } from "../../constants/actions";
import { getBoiteVeOfVh, getModelOfVh, getNbrPlaceOfVh } from "../../actions/annonce";

export default function VheicInfocomp() {
  const [nbrPlace, setNbrPlace] = useState("");
  const [boiteVitess, setBoiteVitess] = useState("");
  const dispatch = useDispatch();
  const { vhmarque } = useSelector((state) => state.global);
  const [modelVc, setModelVc] = useState(""); 

  const handlevhmarque = (e) => {
    e.preventDefault();
    dispatch({ type: IS_MARQUE_DRAWER, payload: true });
  };

  const SimpleSelectOption = ({ label }) => {
    return (
      <div className="select-option">
        <span style={{fontSize: '1.2rem'}}>{label}</span>
        <button style={{fontSize: '1.1rem'}} className="btn">Choisir</button>
      </div>
    );
  };

  const vhvitesse = [
    {
      value: "manuel",
      label: (
        <SimpleSelectOption value="manuel" label="Boite de Vitesse Manuelle" />
      ),
    },
    {
      value: "Automatique",
      label: (
        <SimpleSelectOption
          value="automatique"
          label="Boite de Vitesse automatique"
        />
      ),
    },
  ];
  const vhmodele = [
    {
      value: "Berline",
      label: <SimpleSelectOption value="Berlin" label="Berlines / Citadines" />,
    },
    {
      value: "SUV",
      label: <SimpleSelectOption value="Suv" label="SUV / 4X4" />,
    },
    {
      value: "Sport",
      label: <SimpleSelectOption value="Sport" label="Voitures de sport" />,
    },
    {
      value: "anciennes",
      label: <SimpleSelectOption value="old" label="Voitures anciennes" />,
    },
  ];
  
  const vhplaces = [
    {
      value: "Entre 3 et 4 places",
      label: (
        <SimpleSelectOption
          value="Entre 3 et 4 places"
          label="Entre 3 et 4 places"
        />
      ),
    },
    {
      value: "Entre 5 et 7 places",
      label: (
        <SimpleSelectOption
          value="Entre 5 et 7 places"
          label="Entre 5 et 7 places"
        />
      ),
    },
    {
      value: "7 places ou Plus",
      label: (
        <SimpleSelectOption value="7 places ou Plus" label="7 places ou Plus" />
      ),
    },
  ];

  useEffect(() => {
    dispatch(getModelOfVh(modelVc));
  }, [modelVc]);

  useEffect(() => {
    dispatch(getBoiteVeOfVh(boiteVitess));
  }, [boiteVitess]);

  useEffect(() => {
    dispatch(getNbrPlaceOfVh(nbrPlace));
  }, [nbrPlace]);

  return (
    <div className="vhei-info">
      <p>
        Modèle du véhicule <span>( Facultatif )</span>
      </p>
      <FilterComp
        selected={modelVc}
        options={vhmodele}
        vhei={setModelVc}
      />
      <p>
        Marque du véhicule <span>( Facultatif )</span>
      </p>
      <div className="fake-select" onClick={(e) => handlevhmarque(e)}>
        <span>{vhmarque}</span>
      </div>
      <p>
        Boite de vitesse <span>( Facultatif )</span>
      </p>
      <FilterComp
        placeholder=".........."
        vhei={setBoiteVitess}
        selected={boiteVitess}
        options={vhvitesse}
        className="normal-select"
      />
      <p>
        Nombre de places <span>( Facultatif )</span>
      </p>
      <FilterComp
        placeholder=".........."
        vhei={setNbrPlace}
        options={vhplaces}
        selected={nbrPlace}
        className="normal-select"
      />
    </div>
  );
}
