import { combineReducers } from 'redux'
import user from './user'
import global from './global'
import vheicule from './vheicule'
import annonce from './annonce'
import reservation from './reservation'
import toastReducer from './toastReducer'
import notificationsReducer from './notifications'

const rootReducer = combineReducers({
    user,
    global,
    vheicule,
    annonce,
    reservation,
    toast: toastReducer,
    notifications: notificationsReducer
})

export default rootReducer;