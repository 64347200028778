import React from 'react'
import './styles.scss';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { BASE_URL } from '../../components/config/keys';

export default function DoneReservPage() {
    const history = useHistory()  ;
    //const { facture_pdf } = useSelector(state => state.reservation);
    const contrat_pdf = 'contrat/Version-finale-Contrat-location-vehicule.pdf';

    const handleNav  = (e)=>{
        e.preventDefault();
        history.push('/myreservations')
    }
    const handleBack  = (e)=>{
        e.preventDefault();
        history.push('/showall/voiture')
    }

    const handleViewPDF = () => {
        window.open(`${BASE_URL.replace('/api', '')}/${contrat_pdf}`, '_blank');
    };

    return (
        <div className='done-container'>
            <h3>Réservation Confirmée !</h3>
            <div className='msg-div'>
                <p>
                    En attente de l'approbation de l'annonceur. Délai d'attente entre   
                    <span>
                       {'  10 minutes - 2 heures'}
                    </span>
                </p>
            </div>
            <div className='buttons-div'> 
                <button className='btn'
                onClick={(e)=>handleNav(e)}
                >
                Voir ma réservation
                </button>
                <button className='btn'
                onClick={(e)=>handleBack(e)}
                >
                Retourner à mes recherches
                </button>
                <button className='btn' onClick={handleViewPDF}>
                    Voir contrat de réservation
                </button>
            </div>
        </div>
    )
}