import React from 'react'
import {SwipeableDrawer} from '@mui/material';
import './styles.scss'
import CommentComp from '../commentcomp';
import { useDispatch } from 'react-redux';
import { IS_RATE_DRAWER } from '../../constants/actions';
export default function RateDrawer() {
    const dispatch = useDispatch() ; 
    const hiderate = (e)=>{
        e.preventDefault();
    dispatch({type:IS_RATE_DRAWER,payload:false})
    }
  return (
    <SwipeableDrawer className='rate-drawer' open={true}>
        <div className='rate-container'>
            <div className='rate-head'>
            <span>
            Notes et avis clients
            </span>
            <button className='btn close-btn'
            onClick={(e)=>hiderate(e)}
            >
                X
            </button>
            </div>
            <div className='comment-cont'>
                <CommentComp/>
                <CommentComp/>
            </div>
            <div className='give-rate-div'>
                <span>Noter le dépanneur</span>
                <button className='btn give-btn'>
                Faire un commentaire …..
                </button>
            </div>
        </div>
        </SwipeableDrawer>
  )
}
