import React, { useEffect, useState } from "react";
import { FilterComp, PriceSlider, VheiInfo } from "../../components";
import { Car, Bus, Scooter, Truck } from "../../assets/images";
import "./styles.scss";
import Select from "react-select";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { 
  getAnnonceListe,
  getAnnonceListe2,
  getAnnonceListe3,
  getAnnonceListe4,
  getTypeOfVh, 
  getMarqueOfVh, 
  getNbrPlaceOfVh, 
  getModelOfVh, 
  getSmallPriceOfVh, 
  getBigPriceOfVh, 
  getChauffeurOfVh,
  getBoiteVeOfVh 
} from "../../actions/annonce";
import { apiClient } from "../../actions/api";
import { FILTER_MAX, FILTER_CHECK } from "../../constants/actions";

export default function FilterPage() {
  const { defvh } = useParams();
  const [vhei, setVhei] = useState(defvh);
  const [smallPrice, setSmallPrice] = useState(0);
  const [chauffeur, setChauffeur] = useState(null);
  const [vhmod, setVheimod] = useState("");
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const { model, boite_de_vitess, nbr_places, filter_max_value } = useSelector(
    (state) => state.annonce
  );
  const [bigPrice, setbigPrice] = useState(filter_max_value || 191919191);
  // console.log(filter_max_value)
  const [nbrPlace, setNbrPlace] = useState();
  const { vhmarque } = useSelector((state) => state.global);
  const SelectOption = ({ img, label }) => {
    return (
      <div className="select-option">
        <img alt="select-image" src={img} />
        <span>{label}</span>
        <button className="btn">Choisir</button>
      </div>
    );
  };

  const handleFilterMax = () => {
    apiClient()
      .get(`/annonce/getpricemax`)
      .then((response) => {
        if (response.data.success) {
          const maxValue = parseInt(response.data.data);
          console.log('Max price from API:', maxValue);
          dispatch({ type: FILTER_MAX, payload: maxValue });
        }
      })
      .catch(error => {
        console.error('Error fetching max price:', error);
        dispatch({ type: FILTER_MAX, payload: 191919191 });
      });
  };

  useEffect(() => {
    handleFilterMax();
  }, []);

  useEffect(() => {
    dispatch(getTypeOfVh(vhei))
  }, [vhei])

  useEffect(() => {
    dispatch(getNbrPlaceOfVh(nbrPlace))
  }, [nbrPlace])

  useEffect(() => {
    dispatch(getModelOfVh(vhmod))
  }, [vhmod])

  useEffect(() => { //smallprice
    dispatch(getSmallPriceOfVh(smallPrice))
  }, [smallPrice])

  useEffect(() => { //bigprice
    dispatch(getBigPriceOfVh(bigPrice))
  }, [bigPrice])

  useEffect(() => { //chauffeur
    dispatch(getChauffeurOfVh(chauffeur))
  }, [chauffeur])


  const handleNav = (e) => {
    e.preventDefault();
    
    // Set filter check first
    dispatch({ type: FILTER_CHECK, payload: true });

    // Log the values being sent
    console.log('Filtering with:', {
      smallPrice,
      bigPrice,
      vhei,
      model,
      marque: vhmarque,
      chauffeur
    });

    switch (vhei) {
      case "camion":
        const filteredObject3 = {
          type_de_vh: vhei,
          user_id: user?.user?.id,
          genre_camion: vhmod,
          price_day_from: parseInt(smallPrice),
          price_day_to: parseInt(bigPrice),
          from_filter: true,
        };
        console.log('Camion filter:', filteredObject3);
        dispatch(getAnnonceListe3(filteredObject3));
        break;

      case "bus":
        const filteredObject1 = {
          type_de_vh: vhei,
          user_id: user?.user?.id,
          nbr_plc_bus: nbr_places,
          price_day_from: parseInt(smallPrice),
          price_day_to: parseInt(bigPrice),
          from_filter: true,
        };
        console.log('Bus filter:', filteredObject1);
        dispatch(getAnnonceListe2(filteredObject1));
        break;

      case "voiture":
        const filteredObject = {
          type_de_vh: vhei,
          user_id: user?.user?.id,
          model: model,
          marque: vhmarque,
          boite_de_vitess: boite_de_vitess,
          nbr_places: nbr_places,
          price_day_from: parseInt(smallPrice),
          price_day_to: parseInt(bigPrice),
          chauffeur: chauffeur === true ? 1 : chauffeur === false ? 2 : undefined,
          from_filter: true,
        };
        console.log('Voiture filter:', filteredObject);
        dispatch(getAnnonceListe(filteredObject));
        break;

      case "moto":
        const filteredObject2 = {
          type_de_vh: vhei,
          user_id: user?.user?.id,
          price_day_from: parseInt(smallPrice),
          price_day_to: parseInt(bigPrice),
          from_filter: true,
        };
        console.log('Moto filter:', filteredObject2);
        dispatch(getAnnonceListe4(filteredObject2));
        break;

      default:
        break;
    }

    // Navigate after dispatch
    history.push("/showall/" + vhei + "/page=1");
  };

  const handleChauffeurChange = (avecChauffeur) => {
    setChauffeur((prevChauffeur) => {
      if (prevChauffeur === avecChauffeur) {
        return null; // Clicking the same button twice, set to null
      } else {
        return avecChauffeur; // Clicking a different button, set to the selected value
      }
    });
  };

  const SimpleSelectOption = ({ label }) => {
    return (
      <div className="select-option">
        <span>{label}</span>
        <button className="btn">Choisir</button>
      </div>
    );
  };

  const busPlaces = [
    {
      value: "Entre 8 - 15 places",
      label: <SimpleSelectOption value="Entre 8 - 15 places" label="Entre 8 - 15 places" />,
    },
    {
      value: "Entre 15 - 20 places",
      label: <SimpleSelectOption value="Entre 15 - 20 places" label="Entre 15 - 20 places" />,
    },
    {
      value: "20 places ou 30 places",
      label: <SimpleSelectOption value="20 places ou 30 places" label="20 places ou 30 places" />,
    },
    {
      value: "30 places ou plus",
      label: <SimpleSelectOption value="30 places ou plus" label="30 places ou plus" />,
    },
  ];

  const camionGenre = [
    {
      value: "amenagement",
      label: (
        <SimpleSelectOption
          value="amenagement"
          label="Camion de déménagement"
        />
      ),
    },
    {
      value: "marchandise",
      label: (
        <SimpleSelectOption value="amenagement" label="Camion de marchandise" />
      ),
    },
    {
      value: "benne",
      label: <SimpleSelectOption value="benne" label="Camion benne" />,
    },
    {
      value: "grue",
      label: <SimpleSelectOption value="grue" label="Camion grue" />,
    },
    {
      value: "plateau",
      label: <SimpleSelectOption value="grue" label="Camion plateau" />,
    },
    {
      value: "autres",
      label: <SimpleSelectOption value="autres" label="autres" />,
    },
  ];
  const handletype = () => {
    switch (vhei) {
      case "voiture":
        return <VheiInfo />;
      case "bus":
        return (
          <>
            <p>Nombre des places</p>
            {/* <Select placeholder=".........."></Select> */}
            <FilterComp selected={nbrPlace} vhei={setNbrPlace} options={busPlaces} />

          </>
        );
      case "camion":
        return (
          <>
            <div className="titles-div">
              <p>Quels types de camions voulez-vous ?</p>
              <span>possibilité d en sélectionner plusieurs </span>
            </div>
            <FilterComp
              selected={vhmod}
              vhei={setVheimod}
              options={camionGenre}
            />
          </>
        );
      default:
        break;
    }
  };
  const vhoptions = [
    {
      value: "voiture",
      label: (
        <SelectOption
          className={vhei && "disabled"}
          value="voiture"
          img={Car}
          label="Voitures"
        />
      ),
    },
    {
      value: "bus",
      label: (
        <SelectOption
          className={vhei === "Bus" && "disabled"}
          value="Bus"
          img={Bus}
          label="Bus/Car"
        />
      ),
    },
    {
      value: "moto",
      label: (
        <SelectOption
          img={Scooter}
          className={vhei === "Moto" && "disabled"}
          label="Moto"
          value="Moto"
        />
      ),
    },
    {
      value: "camion",
      label: (
        <SelectOption
          img={Truck}
          className={vhei === "Camion" && "disabled"}
          value="Camion"
          label="Camions"
        />
      ),
    },
  ];

  return (
    <div className="fiter-container">
      <p>Choisis un type de véhicule</p>
      <FilterComp selected={vhei} options={vhoptions} vhei={setVhei} />
      {handletype()}
      <p>Tarif/jours</p>
      <div className="price-range">
        <span>{smallPrice.toLocaleString()} fcfa</span>
        <span>{bigPrice.toLocaleString()} fcfa</span>
      </div>
      <PriceSlider priceFr={setSmallPrice} priceT={setbigPrice} />
      {vhei === "voiture" && (
        <>
          <p>
            Choisisser une option Location <span>( Facultatif )</span>{" "}
          </p>
          <div className="chauffeur-div">
            <button
              className={`btn ${chauffeur === true ? "selected" : ""}`}
              onClick={() => handleChauffeurChange(true)}
            >
              Avec Chauffeur
            </button>
            <button
              className={`btn ${chauffeur === false ? "selected" : ""}`}
              onClick={() => handleChauffeurChange(false)}
            >
              Sans Chauffeur
            </button>
          </div>
        </>
      )}

      <button className="btn show-btn" onClick={(e) => handleNav(e)}>
        Voir les résultats
      </button>
    </div>
  );
}
