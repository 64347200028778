import { 
    MARK_NOTIFICATION_READ, 
    MARK_ALL_NOTIFICATIONS_READ,
    ADD_FCM_NOTIFICATION,
    FETCH_NOTIFICATIONS_SUCCESS,
    SET_UNREAD_COUNT
  } from '../constants/actions';
  import { apiClient } from './api';
  import { BASE_URL } from '../components/config/keys';
  
  export const markNotificationRead = (notificationId) => ({
    type: MARK_NOTIFICATION_READ,
    payload: notificationId
  });
  
  export const markAllNotificationsRead = () => ({
    type: MARK_ALL_NOTIFICATIONS_READ
  });

  export const addFcmNotification = (notification) => ({
    type: ADD_FCM_NOTIFICATION,
    payload: {
      title: notification.title,
      body: notification.body,
      data: notification.data,
      created_at: notification.created_at,
      id: notification.id,
      read: false
    }
  });
  
  export const fetchInitialNotifications = (userId) => async (dispatch) => {
    try {
      const response = await apiClient().get(`${BASE_URL}/notifications/${userId}`);
      dispatch({
        type: FETCH_NOTIFICATIONS_SUCCESS,
        payload: response.data.notifications.data
      });
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };
  
  export const setUnreadCount = (count) => ({
    type: SET_UNREAD_COUNT,
    payload: count
  });
  
  export const fetchUnreadCount = (userId) => async (dispatch) => {
    try {
      const response = await apiClient().get(`${BASE_URL}/notifications/unread-count/${userId}`);
      dispatch(setUnreadCount(response.data.count));
    } catch (error) {
      console.error('Error fetching unread count:', error);
    }
  };
  
  
  