import { COUNTRY_RES, END_DATE, FINAL_PRICE, LIST_REC_OFFRES, LIST_RES_LOCA, NORMAL_PRICE, NUMBER_RES, RED_PRICE, RES_FINAL_DATE, RES_START_DATE, SPECIFIC_RESERVATION, START_DATE, SAVE_FACTURE_PDF } from "../constants/actions"
import { apiClient } from "./api"


export const saveStartDate = (date) => dispatch => {
    dispatch({type: START_DATE, payload: date})
}

export const saveEndDate = (date) => dispatch => {
    dispatch({type: END_DATE, payload: date})
}

export const saveFinalPrice = (price) => dispatch => {
    dispatch({type: FINAL_PRICE, payload: price})
}

export const saveNumberRes = (number) => dispatch => {
    dispatch({type: NUMBER_RES, payload: number})
}

export const saveCountryRes = (country) => dispatch => {
    dispatch({type: COUNTRY_RES, payload: country})
}

export const makeReservation = (params, idloc) => (dispatch) => {
    const data = {
      ...params,
    };
    return apiClient()
      .post(`/reservation/make`, data)
      .then((res) => {
        if(res.data.success){
            dispatch(getReservationsLocat(idloc))
            // Save the PDF path from the response
            //const pdfPath = res.data.facture_pdf.original.path;
            //dispatch(saveFacturePdf(pdfPath));
        }
      });
};


export const getReservationsLocat = (idloc) => (dispatch) => {
    return apiClient()
      .get(`/reservations/locataire/`+idloc)
      .then((res) => {
        if(res.data.success){
            dispatch({type: LIST_RES_LOCA, payload: res.data.reservations})
        }
      });
};

export const specificReservation = (reservat) => (dispatch) => {
  dispatch({type: SPECIFIC_RESERVATION, payload: reservat})
}

export const updateReservation = (idres, params, history) => (dispatch) => {
  
  return apiClient()
  .patch(`/reservation/update/`+idres, params)
  .then((res) => {
    if(res.data.success){
        // history.push('/mesoffres')
    }
  });
}


export const getOffresRec = (idre) => (dispatch) => {
  
  return apiClient()
  .get(`/reservations/annonceur/`+idre)
  .then((res) => {
    if(res.data.success){
        dispatch({type: LIST_REC_OFFRES, payload: res.data.reservations})
    }
  });
}

export const addResPrice = (price) => (dispatch) => {
  dispatch({type: NORMAL_PRICE, payload: price})
} 

export const addResRedPrice = (price) => (dispatch) => {
  dispatch({type: RED_PRICE, payload: price})
} 

export const addStartDateRes = (date) => (dispatch) => {
  dispatch({type: RES_START_DATE, payload: date})
} 

export const addFinalDateRes = (date) => (dispatch) => {
  dispatch({type: RES_FINAL_DATE, payload: date})
} 

export const saveFacturePdf = (pdfPath) => ({
  type: SAVE_FACTURE_PDF,
  payload: pdfPath
});