const initialState = {
  visible: false,
  message: null
};

export default function toastReducer(state = initialState, action) {
  switch (action.type) {
    case 'SHOW_TOAST':
      return {
        visible: true,
        message: action.payload
      };
    case 'HIDE_TOAST':
      return {
        ...state,
        visible: false,
        message: null
      };
    default:
      return state;
  }
} 