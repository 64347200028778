import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { UilAngleLeft, UilListUiAlt } from "@iconscout/react-unicons";
import SideBar from "../sidebar";
import { useHistory } from "react-router-dom";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { IS_PUB_STEP, IS_SIDE_BAR } from "../../constants/actions";
import { createAnnonce, getAnnonceListe, updateAnnonce } from "../../actions/annonce";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function PubHeader({updatepub}) {
  const { pubstep, typechoice } = useSelector((state) => state.global);
  const { user } = useSelector((state) => state.user);
  const { spec_annonce } = useSelector((state) => state.annonce);
  const [error, setError] = useState(null);

  useEffect(() => {
    if(error){
      toast.error(error)
      setError(null);
    }
  }, [error])

  const dispatch = useDispatch();
  const history = useHistory();
  const {
    type_de_vh,
    model,
    marque,
    annee_vh,
    boite_de_vitess,
    kilometrage,
    nbr_places,
    chauffeur,
    price_day,
    remise,
    pourcentage,
    title,
    description,
    pics_inter,
    pics_exter,
  } = useSelector((state) => state.annonce);
  const handleNav = (step) => {
    if (pubstep == 1) {
      dispatch({ type: IS_SIDE_BAR, payload: false });

      if(updatepub){
        history.push("/mypubs");
      } else {
        history.push("/showall/"+typechoice);
      }
    } else {
      dispatch({ type: IS_SIDE_BAR, payload: false });
      dispatch({ type: IS_PUB_STEP, payload: step });
      if(updatepub){
        history.push("/updatepub/" + step);
      } else {
        history.push("/newpub/" + step);
      }
    }
  };

  let formData = {
    type_de_vh: type_de_vh,
    model: model,
    marque: marque,
    Annee_vh: parseInt(annee_vh, 10),
    boite_de_vitess: boite_de_vitess,
    kilometrage: kilometrage,
    nbr_places: nbr_places,
    chauffeur: chauffeur,
    price_day: price_day,
    remise: remise,
    pourcentage: pourcentage,
    title: title,
    description: description,
    existing_images_exter: pics_exter,
    existing_images_inter: pics_inter,
    userId: user?.user?.id,
  };

  let formData2 = {
    type_de_vh: type_de_vh,
    model: model,
    marque: marque ? marque : spec_annonce?.marque,
    Annee_vh: parseInt(annee_vh, 10),
    boite_de_vitess: boite_de_vitess,
    kilometrage: kilometrage,
    nbr_places: nbr_places,
    chauffeur: chauffeur,
    price_day: price_day,
    remise: remise,
    pourcentage: pourcentage,
    title: title,
    description: description,
    pics_inter: pics_inter,
    pics_exter: pics_exter,
    userId: user?.user?.id,
    annonce_id: spec_annonce?.id
  };


  const handlesteps = (step) => {
    if (pubstep != 3) {
      dispatch({ type: IS_PUB_STEP, payload: step });
      if(updatepub){
        history.push("/updatepub/" + step);
      } else {
        history.push("/newpub/" + step);
      }
    } else {
      console.log(formData)
      switch (type_de_vh) {
        case 'voiture':
          if (
            !model ||
            !marque ||
            !annee_vh ||
            !boite_de_vitess ||
            !kilometrage ||
            !nbr_places ||
            // !chauffeur ||
            !price_day ||
            // !remise ||
            // !pourcentage ||
            !title ||
            !description
          ) {
            setError("Veuillez remplir tous les champs");
            return;
          } if (pics_inter.length === 0) {
            setError("Veuillez ajouter au moins une photo intérieure");
            return;
          } if (pics_exter.length === 0) {
            setError("Veuillez ajouter au moins une photo extérieure");
            return;
          }
          break;
        case 'bus':
          if (
            !nbr_places ||
            !price_day ||
            // !remise ||
            // !pourcentage ||
            !title ||
            !description
          ) {
            setError("Veuillez remplir tous les champs");
            return;
          } if (pics_inter.length === 0) {
            setError("Veuillez ajouter au moins une photo intérieure");
            return;
          } if (pics_exter.length === 0) {
            setError("Veuillez ajouter au moins une photo extérieure");
            return;
          }
          break;
      
        case 'moto':
          if (
            !marque ||
            !annee_vh ||
            !price_day ||
            // !remise ||
            // !pourcentage ||
            !title ||
            !description
          ) {
            setError("Veuillez remplir tous les champs");
            return;
          } if (pics_inter.length === 0) {
            setError("Veuillez ajouter au moins une photo intérieure");
            return;
          } if (pics_exter.length === 0) {
            setError("Veuillez ajouter au moins une photo extérieure");
            return;
          }
          break;
        case 'camion':
          if (
            !model || //genre
            !annee_vh ||
            !price_day ||
            // !remise ||
            // !pourcentage ||
            !title ||
            !description
          ) {
            setError("Veuillez remplir tous les champs");
            return;
          } if (pics_inter.length === 0) {
            setError("Veuillez ajouter au moins une photo intérieure");
            return;
          } if (pics_exter.length === 0) {
            setError("Veuillez ajouter au moins une photo extérieure");
            return;
          }
          break;
        default:
          break;
      }
      if(updatepub){
        switch (type_de_vh) {
          case 'voiture':
            if (
              !model ||
              !marque ||
              !annee_vh ||
              !boite_de_vitess ||
              !kilometrage ||
              !nbr_places ||
              !chauffeur ||
              !price_day ||
              // !remise ||
              // !pourcentage ||
              !title ||
              !description
            ) {
              setError("Veuillez remplir tous les champs");
              return;
            } if (pics_inter.length === 0) {
              setError("Veuillez ajouter au moins une photo intérieure");
              return;
            } if (pics_exter.length === 0) {
              setError("Veuillez ajouter au moins une photo extérieure");
              return;
            }
            break;
          case 'bus':
            if (
              !nbr_places ||
              !price_day ||
              // !remise ||
              // !pourcentage ||
              !title ||
              !description
            ) {
              setError("Veuillez remplir tous les champs");
              return;
            } if (pics_inter.length === 0) {
              setError("Veuillez ajouter au moins une photo intérieure");
              return;
            } if (pics_exter.length === 0) {
              setError("Veuillez ajouter au moins une photo extérieure");
              return;
            }
            break;
        
          case 'moto':
            if (
              !marque ||
              !annee_vh ||
              !price_day ||
              // !remise ||
              // !pourcentage ||
              !title ||
              !description
            ) {
              setError("Veuillez remplir tous les champs");
              return;
            } if (pics_inter.length === 0) {
              setError("Veuillez ajouter au moins une photo intérieure");
              return;
            } if (pics_exter.length === 0) {
              setError("Veuillez ajouter au moins une photo extérieure");
              return;
            }
            break;
          case 'camion':
            if (
              !model || //genre
              !annee_vh ||
              !price_day ||
              // !remise ||
              // !pourcentage ||
              !title ||
              !description
            ) {
              setError("Veuillez remplir tous les champs");
              return;
            } if (pics_inter.length === 0) {
              setError("Veuillez ajouter au moins une photo intérieure");
              return;
            } if (pics_exter.length === 0) {
              setError("Veuillez ajouter au moins une photo extérieure");
              return;
            }
            break;
          default:
            break;
        }
        dispatch(updateAnnonce(formData2)).then(() => {
          dispatch(getAnnonceListe({type_de_vh: typechoice, user_id: user?.user?.id})) // get the choosen type
          history.push("/mypubs");
        });
      } else {
        dispatch(createAnnonce(formData)).then(() => {
          dispatch(getAnnonceListe({type_de_vh: typechoice, user_id: user?.user?.id})) // get the choosen type
          history.push("/mypubs");
        });
      }
    }
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" className="mobile-header pub-header">
          <Toolbar className="mobile-header-inner">
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => handleNav(pubstep - 1)}
            >
              <UilAngleLeft size="30" />
              Retour
            </IconButton>
            <div className="numbers-div">
              <span id={pubstep == 1 && "selected"}>1</span>
              <span id={pubstep == 2 && "selected"}>2</span>
              <span id={pubstep == 3 && "selected"}>3</span>
            </div>
            <button
              className="btn retr-btn"
              onClick={() => handlesteps(pubstep + 1)}
            >
              {pubstep <= 2 ? "Suivant" : "Terminer"}
            </button>
          </Toolbar>
        </AppBar>
      </Box>
      <ToastContainer />
    </>
  );
}
