import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './styles.scss';

const Toast = () => {
  const dispatch = useDispatch();
  const toast = useSelector(state => state.toast) || { visible: false, message: null };
  const { visible, message } = toast;

  useEffect(() => {
    if (visible && message) {
      const timer = setTimeout(() => {
        dispatch({ type: 'HIDE_TOAST' });
      }, 300000);
      return () => clearTimeout(timer);
    }
  }, [visible, message, dispatch]);

  if (!visible || !message) return null;

  return (
    <div className="toast">
      <div className="toast-content">
        <h4>{message.title}</h4>
        <p>{message.body}</p>
      </div>
      <button onClick={() => dispatch({ type: 'HIDE_TOAST' })}>×</button>
    </div>
  );
};

export default Toast; 