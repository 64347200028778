import React from "react";
import "./styles.scss";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { UilAngleLeft, UilListUiAlt } from "@iconscout/react-unicons";
import SideBar from "../sidebar";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IS_SIDE_BAR } from "../../constants/actions";

export default function MobileHeader(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { typechoice } = useSelector((state) => state.global)

  const showSide = (e) => {
    e.preventDefault();
    dispatch({ type: IS_SIDE_BAR, payload: true });
  };

  const handleNavigate = (e) => {
    e.preventDefault();
    switch (props.text) {
      case "Détails de l'annonce":
      case "Mes informations":
      case "Offres reçues":
      case "Mes annonces publiées":
        history.push("/showall/"+typechoice)
        break;
      case "Faire une réservation":
        history.push("/choosepage")
        break;
      case "Mes réservations":
        if(props?.special == true){
          history.push("/showall/"+typechoice)
          break;
        } else {
          history.goBack();
          break;
        }
      case "Offre reçue":
        history.push("/mesoffres")
      break;
      // if(props?.hadno){
        // } else{
        // history.push("/showall/"+typechoice)
        // }
      default:
        history.goBack()
        break;
    }
  }
  return (
    <div className="mobile-header1">
      <SideBar btn={false} />
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" className="mobile-header">
          <Toolbar className="mobile-header-inner">
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={(e) => handleNavigate(e)}
            >
              <UilAngleLeft size="30" />
            </IconButton>
            <span>{props.text}</span>
            <Button color="inherit" onClick={(e) => showSide(e)}>
              <UilListUiAlt size="30" />
            </Button>
          </Toolbar>
        </AppBar>
      </Box>
    </div>
  );
}
