import React, { useEffect, useState } from "react";
import Slider from "@mui/material/Slider";
import "./styles.scss";
import { useSelector } from "react-redux";

export default function SliderComp({ priceFr, priceT }) {
  const { filter_max_value } = useSelector((state) => state.annonce);
  
  // Log the initial max value
  console.log('filter_max_value:', filter_max_value);
  
  // Don't update maxval after initial render
  const [maxval] = useState(filter_max_value ? parseInt(filter_max_value) : 935000);
  
  // Initialize value state
  const [value, setValue] = useState([0, maxval]);

  // Log when value changes
  console.log('Current slider values:', value);

  const handleChange = (event, newValue) => {
    // Log the new value being set
    console.log('handleChange newValue:', newValue);
    setValue(newValue);
  };

  useEffect(() => {
    const [priceFrom, priceTo] = value;
    // Log the values being sent to parent
    console.log('Updating parent with:', { priceFrom, priceTo });
    priceFr(parseInt(priceFrom));
    priceT(parseInt(priceTo));
  }, [value]);

  return (
    <Slider
      value={value}
      onChange={handleChange}
      color="secondary"
      step={500}
      min={0}
      max={maxval}
      // Add marks to see the scale
      marks={[
        { value: 0, label: '0' },
        { value: maxval, label: `${maxval}` }
      ]}
      valueLabelDisplay="auto"
    />
  );
}
