import React, { useEffect, useState } from "react";
import { Car, Bus, Scooter, Truck } from "../../assets/images";
import { FilterComp } from "../../components";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { IS_MARQUE_DRAWER } from "../../constants/actions";
import { getAnneeOfVh, getBoiteVeOfVh, getChauffeurOfVh, getKmeOfVh, getMarqueOfVh, getModelOfVh, getNbrPlaceOfVh, getPourcentageOfVh, getPriceOfVh, getRemiseOfVh, getTypeOfVh } from "../../actions/annonce";

export default function FirstUpdatePage({data_annonce}) {
  const {
    type_de_vh,
    model,
    marque,
    annee_vh,
    boite_de_vitess, 
    kilometrage,
    nbr_places,
    chauffeur,
    price_day,
    remise,
    pourcentage, 
  } = useSelector((state) => state.annonce)

  const [vhtype, setVhtype] = useState(type_de_vh);
  const { pubstep } = useSelector((state) => state.global);
  const [vhmod, setVheimod] = useState("");
  const [boiteVitess, setBoiteVitess] = useState(boite_de_vitess);
  const [kilometrage1, setKilometrage] = useState(kilometrage);
  const [nbrPlace, setNbrPlace] = useState(nbr_places);
  const dispatch = useDispatch();
  const { vhmarque } = useSelector((state) => state.global);
  const [pricePerDay, setPricePerDay] = useState(price_day);
  const [modelVc, setModelVc] = useState(model); // type de vehicule
  const [anneeVh, setAnneeVh] = useState(annee_vh);
  const [chauffeur1, setChaffeur] = useState(chauffeur == 1 ? true : false);
  const [reductionJr, setReductionJr] = useState(remise);
  const [percent, setPercentage] = useState(pourcentage);
  const [title, setTitleAnnonce] = useState("");
  const [descriptionText, setDescriptionText] = useState("");
  const [ifloading, setIfloading] = useState(0); // for loading
  const [motoMarque, setMotoMarque] = useState(marque);
 
  useEffect(() => {
    dispatch(getTypeOfVh(vhtype));
  }, [vhtype]);

  useEffect(() => {
    dispatch(getModelOfVh(modelVc));
  }, [modelVc]);

  useEffect(() => {
    switch (vhtype) {
      case 'voiture':
        dispatch(getMarqueOfVh(vhmarque));
        break;
      case 'moto':
        dispatch(getMarqueOfVh(motoMarque));
        break;
      default:
        break;
    }
  }, [vhmarque, motoMarque]);

  useEffect(() => {
    dispatch(getAnneeOfVh(anneeVh));
  }, [anneeVh]);

  useEffect(() => {
    dispatch(getBoiteVeOfVh(boiteVitess));
  }, [boiteVitess]);

  useEffect(() => {
    dispatch(getKmeOfVh(kilometrage1));
  }, [kilometrage1]);

  useEffect(() => {
    dispatch(getNbrPlaceOfVh(nbrPlace));
  }, [nbrPlace]);

  useEffect(() => {
    dispatch(getChauffeurOfVh(chauffeur1));
  }, [chauffeur1]);

  useEffect(() => {
    dispatch(getPriceOfVh(pricePerDay));
  }, [pricePerDay]);

  useEffect(() => {
    dispatch(getRemiseOfVh(reductionJr));
  }, [reductionJr]);

  useEffect(() => {
    dispatch(getPourcentageOfVh(percent));
  }, [percent]);

  useEffect(() => {
      dispatch(getAnneeOfVh(anneeVh));
      dispatch(getBoiteVeOfVh(boiteVitess));
      dispatch(getKmeOfVh(kilometrage));
      dispatch(getNbrPlaceOfVh(nbrPlace));   
      dispatch(getChauffeurOfVh(chauffeur));
      dispatch(getPriceOfVh(pricePerDay));
      dispatch(getRemiseOfVh(reductionJr));
      dispatch(getPourcentageOfVh(percent));
      switch (vhtype) {
        case 'voiture':
          dispatch(getMarqueOfVh(vhmarque));
          break;
        case 'moto':
          dispatch(getMarqueOfVh(motoMarque));
          break;
        default:
          break;
      }
      dispatch(getTypeOfVh(vhtype));
      dispatch(getModelOfVh(modelVc));
    
   }, [data_annonce]);


  const handlevhmarque = (e) => {
    e.preventDefault();
    dispatch({ type: IS_MARQUE_DRAWER, payload: true });
  };

  //   const handlemarque = (e) => {
  //     e.preventDefault();
  //     dispatch({ type: IS_MARQUE_DRAWER, payload: true });
  //   };

  const handleVhtype = (vhtype) => {
    return specialsOptions.filter((item) => item.vh === vhtype)[0].comp;
  };

  const SelectOption = ({ img, label }) => {
    return (
      <div className="select-option">
        <img alt="select-image" src={img} />
        <span>{label}</span>
        <button className="btn">Choisir</button>
      </div>
    );
  };
  const vhoptions = [
    {
      value: "voiture",
      label: <SelectOption value="voiture" img={Car} label="voiture" />,
    },
    {
      value: "bus",
      label: <SelectOption value="Bus" img={Bus} label="Bus/Car" />,
    },
    {
      value: "moto",
      label: <SelectOption img={Scooter} label="Moto" />,
    },
    {
      value: "camion",
      label: <SelectOption img={Truck} label="Camion" />,
    },
  ];
  const SimpleSelectOption = ({ label }) => {
    return (
      <div className="select-option">
        <span>{label}</span>
        <button className="btn">Choisir</button>
      </div>
    );
  };

  const vhvitesse = [
    {
      value: "Vitesse Manuelle",
      label: (
        <SimpleSelectOption value="Vitesse Manuelle" label="Boite de Vitesse Manuelle" />
      ),
    },
    {
      value: "Vitesse Automatique",
      label: (
        <SimpleSelectOption
          value="Vitesse Automatique"
          label="Boite de Vitesse automatique"
        />
      ),
    },
  ];
  const vhmodele = [
    {
      value: "Berline",
      label: <SimpleSelectOption value="Berlin" label="Berlines / Citadines" />,
    },
    {
      value: "SUV",
      label: <SimpleSelectOption value="Suv" label="SUV / 4X4" />,
    },
    {
      value: "Sport",
      label: <SimpleSelectOption value="Sport" label="Voitures de sport" />,
    },
    {
      value: "anciennes",
      label: <SimpleSelectOption value="old" label="Voitures anciennes" />,
    },
  ];
  const motomoarque = [
    {
      value: "Harley-Davidson",
      label: <SimpleSelectOption value="Harley-Davidson" label="Harley-Davidson" />,
    },
    {
      value: "Honda",
      label: <SimpleSelectOption value="Honda" label="Honda moto" />,
    },
    {
      value: "Yamaha",
      label: <SimpleSelectOption value="Yamaha" label="Yamaha" />,
    },
    {
      value: "Ducati",
      label: <SimpleSelectOption value="Ducati" label="Ducati" />,
    },
  ];
  const vhkilo = [
    {
      value: "0 to 10k",
      label: <SimpleSelectOption value="0 to 10k" label="0 KM - 10 000KM " />,
    },
    {
      value: "10k to 50k",
      label: (
        <SimpleSelectOption value="10k to 50k" label="10 000KM - 50 000KM" />
      ),
    },
    {
      value: "50k to 100k",
      label: (
        <SimpleSelectOption value="50k to 100k" label="50 000KM - 100 000KM" />
      ),
    },
    {
      value: "100k to 200k",
      label: (
        <SimpleSelectOption
          value="100k to 200k"
          label="100 000KM - 200 000KM"
        />
      ),
    },
    {
      value: "plus 200k",
      label: <SimpleSelectOption value="plus 200k" label="plus de 200 000KM" />,
    },
  ];
  const vhplaces = [
    {
      value: "Entre 3 et 4 places",
      label: (
        <SimpleSelectOption
          value="Entre 3 et 4 places"
          label="Entre 3 et 4 places"
        />
      ),
    },
    {
      value: "Entre 5 et 7 places",
      label: (
        <SimpleSelectOption
          value="Entre 5 et 7 places"
          label="Entre 5 et 7 places"
        />
      ),
    },
    {
      value: "7 places ou Plus",
      label: (
        <SimpleSelectOption value="7 places ou Plus" label="7 places ou Plus" />
      ),
    },
  ];
  const busPlaces = [
    {
      value: "Entre 8 - 15 places",
      label: <SimpleSelectOption value="Entre 8 - 15 places" label="Entre 8 - 15 places" />,
    },
    {
      value: "Entre 15 - 20 places",
      label: <SimpleSelectOption value="Entre 15 - 20 places" label="Entre 15 - 20 places" />,
    },
    {
      value: "20 places ou 30 places",
      label: <SimpleSelectOption value="20 places ou 30 places" label="20 places ou 30 places" />,
    },
    {
      value: "30 places ou plus",
      label: <SimpleSelectOption value="30 places ou plus" label="30 places ou plus" />,
    },
  ];
  const vhreduction = [
    {
      value: "5",
      label: <SimpleSelectOption value="5" label="A partir de 5 jours" />,
    },
    {
      value: "10",
      label: <SimpleSelectOption value="10" label="A partir de 10 jours" />,
    },
    {
      value: "15",
      label: <SimpleSelectOption value="15" label="A partir de 15 jours" />,
    },
    {
      value: "20",
      label: <SimpleSelectOption value="20" label="A partir de 20 jours" />,
    },
    {
      value: "21",
      label: <SimpleSelectOption value="20" label="plus de 20 Jours" />,
    },
  ];
  const percentageList = [
    {
      value: "5",
      label: <SimpleSelectOption value="5" label="5 % de réduction" />,
    },
    {
      value: "10",
      label: <SimpleSelectOption value="10" label="10 % de réduction" />,
    },
    {
      value: "15",
      label: <SimpleSelectOption value="15" label="15 % de réduction" />,
    },
    {
      value: "20",
      label: <SimpleSelectOption value="20" label="20 % de réduction" />,
    },
    {
      value: "25",
      label: <SimpleSelectOption value="25" label="25 % de réduction" />,
    },
  ];
  const camionGenre = [
    {
      value: "Camion de déménagement",
      label: (
        <SimpleSelectOption
          value="Camion de déménagement"
          label="Camion de déménagement"
        />
      ),
    },
    {
      value: "Camion de marchandise",
      label: (
        <SimpleSelectOption value="Camion de marchandise" label="Camion de marchandise" />
      ),
    },
    {
      value: "Camion benne",
      label: <SimpleSelectOption value="Camion benne" label="Camion benne" />,
    },
    {
      value: "Camion grue",
      label: <SimpleSelectOption value="Camion grue" label="Camion grue" />,
    },
    {
      value: "Camion plateau",
      label: <SimpleSelectOption value="Camion plateau" label="Camion plateau" />,
    },
    {
      value: "autres",
      label: <SimpleSelectOption value="autres" label="autres" />,
    },
  ];
  const specialsOptions = [
    {
      vh: "voiture",
      comp: (
        <>
          <div className="infos-div">
            <span>Modèle du véhicule</span>
            <FilterComp
              vhei={setModelVc}
              selected={modelVc}
              options={vhmodele}
            />
          </div>
          <div className="infos-div">
            <span>Marques du véhicule</span>
            {/* <FilterComp onClick ={(e)=>{handlemarque(e)}}options={vhmarque} selected='voiture'  /> */}
            <div className="fake-select" onClick={(e) => handlevhmarque(e)}>
              <span>{vhmarque}</span>
            </div>
          </div>
          <div className="infos-div">
            <span>Année du véhicule</span>
            <input
              type="text"
              value={anneeVh}
              onChange={(e) => setAnneeVh(e.target.value)}
            />
          </div>
          <div className="infos-div">
            <span>Boite de Vitesse</span>
            <FilterComp
              vhei={setBoiteVitess}
              selected={boiteVitess}
              options={vhvitesse}
            />
          </div>
          <div className="infos-div">
            <span>Kilométrage</span>
            <FilterComp
              selected={kilometrage1}
              vhei={setKilometrage}
              options={vhkilo}
            />
          </div>
          <div className="infos-div">
            <span>Nombre de place</span>
            <FilterComp
              selected={nbrPlace}
              vhei={setNbrPlace}
              options={vhplaces}
            />
          </div>
          <div className="check-div">
            <p>Faut-il louer avec votre chauffeur ?</p>
            <div className="choice">
              <span>Oui, uniquement avec mon chauffeur</span>
              <input
                type="checkbox"
                checked={chauffeur1}
                onChange={(e) => setChaffeur(e.target.checked)}
              />
            </div>
            <div className="choice">
              <span>Non, avec ou sans mon chauffeur</span>
              <input
                type="checkbox"
                checked={!chauffeur1}
                onChange={(event) => setChaffeur(!event.target.checked)}
              />
            </div>
          </div>
        </>
      ),
    },
    {
      vh: "camion",
      comp: (
        <>
          <div className="infos-div">
            <span>Quel genre de camion avez-vous ?</span>
            <FilterComp
              selected={modelVc}
              vhei={setModelVc}
              options={camionGenre}
            />
          </div>
          <div className="infos-div">
            <span>Année du camion</span>
            <input
              type="text"
              value={anneeVh}
              onChange={(e) => setAnneeVh(e.target.value)}
            />
          </div>
        </>
      ),
    },
    {
      vh: "bus",
      comp: (
        <>
          <div className="infos-div">
            <span>Combien de places</span>
            <FilterComp selected={nbrPlace} vhei={setNbrPlace} options={busPlaces} />
          </div>
        </>
      ),
    },
    {
      vh: "moto",
      comp: (
        <>
          <div className="infos-div">
            <span>Marque de la Moto</span>
            <FilterComp
              selected={motoMarque}
              vhei={setMotoMarque}
              options={motomoarque}
            />
          </div>
          <div className="infos-div">
            <span>Année de La Moto</span>
            <input
              type="text"
              value={anneeVh}
              onChange={(e) => setAnneeVh(e.target.value)}
            />
          </div>
        </>
      ),
    },
  ];


    return (
        <>
          <div className="infos-div">
            <span>Type de véhicule </span>
            <FilterComp
              selected={vhtype}
              options={vhoptions}
              vhei={setVhtype}
            />
          </div>
          {handleVhtype(vhtype)}
          <div className="infos-div">
            <span>Combien coûte la location du véhicule / jour</span>
            <input
              type="text"
              value={pricePerDay}
              onChange={(e) => setPricePerDay(e.target.value)}
            ></input>
          </div>
          <div className="infos-div">
            <span>Réduction pour les longues réservations</span>
            <FilterComp options={vhreduction} vhei={setReductionJr} selected={reductionJr}/>
          </div>
          <div className="infos-div">
            <span>Combien de pourcentage ?</span>
            <FilterComp options={percentageList} vhei={setPercentage} selected={percent} />
          </div>
        </>
    )
}
