import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, isSupported } from "firebase/messaging";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize messaging with error handling
let messaging = null;

const initializeMessaging = async () => {
  try {
    // Check if messaging is supported
    const isMessagingSupported = await isSupported();
    
    if (isMessagingSupported && !window.ReactNativeWebView) {
      return getMessaging(app);
    } else {
      console.log('Firebase messaging is not supported in this environment');
      return null;
    }
  } catch (error) {
    console.error('Error initializing Firebase messaging:', error);
    return null;
  }
};

export { initializeMessaging };