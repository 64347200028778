import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import { SwipeableDrawer } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { IS_DRAWER, IS_DRAWER_PAY } from "../../constants/actions";
import { useHistory } from "react-router-dom";
import { addFinalDateRes, addResPrice, addResRedPrice, addStartDateRes } from "../../actions/reservation";

export default function OverflowComp() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { drawer } = useSelector((state) => state.global);
  const drawerRef = useRef(null);

  const closeDrawer = (e) => {
    e.preventDefault();
    dispatch({ type: IS_DRAWER, payload: false });
    dispatch(addResPrice(''));
    dispatch(addResRedPrice(''));
    dispatch(addStartDateRes(''));
    dispatch(addFinalDateRes(''));
    history.push("/done");
  };

  const handleCloseDrawer = () => {
    dispatch({ type: IS_DRAWER, payload: false });
  };

  return (
    <SwipeableDrawer
      className="swipe-drawer"
      open={drawer}
      onClose={() => handleCloseDrawer()}
      onOpen={() => {}}
      ref={drawerRef}
    >
      <div className="swipe-container">
        <h3>Bon à savoir</h3>
        <ul>
          <li>
            En vous offrant la possibilité de payer en ligne ou dans nos locaux
            KLEEVER, nous visons à vous offrir une tranquillité d'esprit totale
            lors de vos locations de véhicules.
          </li>
          <li>
            Nous gelons votre paiement jusqu'à ce que la location ait lieu entre
            le locataire et l'annonceur. L'annonceur reçoit votre paiement
            uniquement lorsque le locataire récupère le véhicule.
          </li>
          <li>
            Nous vous protégeons contre les annonces frauduleuses grâce à nos
            mesures de sécurité avancées.
          </li>
          <li>
            En plus de cela, nous nous occupons de la gestion de litiges
            potentiels entre le locataire et l'annonceur.
            <br />
            Cela permet de garantir une résolution rapide et équitable en cas de
            problème.
          </li>
        </ul>
        <br />
        <button className="btn confirme-btn" onClick={(e) => closeDrawer(e)}>
          D'accord
        </button>
      </div>
    </SwipeableDrawer>
  );
}
