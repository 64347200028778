import { initializeMessaging } from '../firebase-config';
import { getToken, deleteToken, onMessage } from 'firebase/messaging';
import { apiClient } from '../actions/api';
import { store } from '../store';
import { addFcmNotification, fetchUnreadCount } from '../actions/notifications';
import { BASE_URL } from '../components/config/keys';


const VAPID_KEY = 'BCyb8Zjvyg7as55htejwLFg_EwAFk08ncr6O4nototuIt1R5AtECnVYzkyplk_n8m9QuhO6mvvU_nq8caNQJxyM';

class FCMService {
  static messaging = null;

  static async initialize() {
    try {
      this.messaging = await initializeMessaging();
      if (!this.messaging) {
        throw new Error('Messaging initialization failed');
      }
    } catch (error) {
      console.error('Error initializing FCM service:', error);
      throw error;
    }
  }

  static async getCurrentToken() {
    try {
      // Ensure messaging is initialized
      if (!this.messaging) {
        await this.initialize();
      }

      const permission = await Notification.requestPermission();
      if (permission !== 'granted') {
        throw new Error('Notification permission denied');
      }

      if (!('serviceWorker' in navigator)) {
        throw new Error('Service worker not supported');
      }

      const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
      console.log('Service Worker registered:', registration);

      // Get FCM token using the initialized messaging instance
      const token = await getToken(this.messaging, { 
        vapidKey: VAPID_KEY,
      });

      console.log('FCM Token:', token);
      return token;
    } catch (error) {
      console.error('Error getting FCM token:', error);
      return null;
    }
  }

  static async registerDevice(userId) {
    try {
      const token = await this.getCurrentToken();
      console.log("token",token);
      if (!token) return null;

      await apiClient().post(`${BASE_URL}/fcm/register`, {
        user_id: userId,
        fcm_token: token
      });

      return token;
    } catch (error) {
      console.error('FCM registration failed:', error);
      throw error;
    }
  }

  static async initializeNotifications() {
    try {
      // Ensure messaging is initialized
      if (!this.messaging) {
        await this.initialize();
      }

      const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
      console.log('Service Worker registered:', registration);

      const currentToken = await this.getCurrentToken();
      
      if (currentToken) {
        console.log('FCM Token:', currentToken);
      } else {
        console.log('No token available');
      }

      // Handle foreground messages
      onMessage(this.messaging, (payload) => {
        console.log('Received foreground message:', payload);
        console.log("payload",payload);
        
        // Dispatch to Redux store using the action creator
        store.dispatch(addFcmNotification({
          title: payload.notification.title,
          body: payload.notification.body,
          data: payload.data || {},
          created_at: payload.data.created_at,
          id: payload.data.notification_id
        }));
        // 
        // Also dispatch toast if needed
        // store.dispatch({
        //   type: 'SHOW_TOAST',
        //   payload: {
        //     title: payload.notification.title,
        //     body: payload.notification.body,
        //     type: payload.data?.type || 'info'
        //   }
        // });
        // Optionally fetch latest unread count from server
        const userId = store.getState().user?.user?.id;
        console.log("userId",userId);
        if (userId) {
          store.dispatch(fetchUnreadCount(userId));
        }
      });

    } catch (error) {
      console.error('Error initializing notifications:', error);
    }
  }

  static async requestNotificationPermission() {
    if (!('Notification' in window)) {
      console.log('Notifications not supported');
      return false;
    }

    try {
      const permission = await Notification.requestPermission();
      return permission === 'granted';
    } catch (error) {
      console.error('Error requesting notification permission:', error);
      return false;
    }
  }

  static async registerNotifications(userId) {
    const hasPermission = await this.requestNotificationPermission();
    if (!hasPermission) {
      console.log('Notification permission denied');
      return;
    }

    try {
      const token = await this.getCurrentToken();
      if (token) {
        await this.registerDevice(userId, token);
      }
    } catch (error) {
      console.error('Error registering notifications:', error);
    }
  }

  static async unregisterDevice() {
    try {
      await deleteToken(this.messaging);
    } catch (error) {
      console.error('FCM unregistration failed:', error);
      throw error;
    }
  }
  
    }

export default FCMService; 