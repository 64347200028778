import React, { useEffect, useState, useMemo, useCallback } from "react";
import "./styles.scss";
import { UilSearch, UilAngleDown, UilRefresh } from "@iconscout/react-unicons";
import { ShowVheic } from "../../components";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UilFilter } from "@iconscout/react-unicons";
import {
  getAnnonceListe,
  getAnnonceListe2,
  getAnnonceListe3,
  getAnnonceListe4,
  getBigPriceOfVh,
  getBoiteVeOfVh,
  getChauffeurOfVh,
  getMarqueOfVh,
  getModelOfVh,
  getNbrPlaceOfVh,
  getSmallPriceOfVh,
  getTypeOfVh,
} from "../../actions/annonce";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Loader from "react-js-loader";
import SearchIcon from "@mui/icons-material/Search";
import { apiClient } from "../../actions/api";
import { FILTER_MAX, VH_MARQUE } from "../../constants/actions";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { debounce } from 'lodash';

export default function ShowAllPage() {
  const vheicule = useSelector((state) => state.vheicule.vheicule);
  const { liste_annonce } = useSelector((state) => state.annonce);
  const { genre_camion_sr, nbr_plc_sr } = useSelector((state) => state.global);
  const [loading, setLoading] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(12);
  const { user } = useSelector((state) => state.user);
  const { type } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOption, setSortOption] = useState(null);
  const [filtredListe, setFiltredListe] = useState([]);
  const [intervalId, setIntervalId] = useState(null);
  const {
    model,
    boite_de_vitess,
    nbr_places,
    type_de_vh,
    marque,
    chauffeur,
    big_prico,
    small_prico,
  } = useSelector((state) => state.annonce);
  const { vhmarque } = useSelector((state) => state.global);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentMesAnnonces = liste_annonce?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const handleChangePage = useCallback((_, newPage) => {
    setCurrentPage(newPage);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const handleNav = useCallback((e) => {
    e.preventDefault();
    dispatch(getTypeOfVh("voiture"));
    dispatch(getMarqueOfVh(""));
    dispatch(getNbrPlaceOfVh(""));
    dispatch(getModelOfVh(""));
    dispatch(getSmallPriceOfVh(""));
    dispatch(getBigPriceOfVh(""));
    dispatch(getChauffeurOfVh(""));
    dispatch({ type: VH_MARQUE, payload: '' });
    dispatch(getBoiteVeOfVh(""));
    history.push("/filter/" + type);
  }, [dispatch, type, history]);

  const handleResetFilters = useCallback((e) => {
    e.preventDefault();
    setLoading(1);
    
    // Reset all filters
    dispatch(getTypeOfVh(""));
    dispatch(getMarqueOfVh(""));
    dispatch(getNbrPlaceOfVh(""));
    dispatch(getModelOfVh(""));
    dispatch(getSmallPriceOfVh(0));  // Set to 0 instead of empty string
    dispatch(getBigPriceOfVh(0));    // Set to 0 instead of empty string
    dispatch(getChauffeurOfVh(null)); // Set to null instead of empty string
    dispatch({ type: VH_MARQUE, payload: '' });
    dispatch(getBoiteVeOfVh(""));

    // Fetch unfiltered data based on current type
    switch (type) {
      case "voiture":
        dispatch(getAnnonceListe({ type_de_vh: type, user_id: user?.user?.id }));
        break;
      case "bus":
        dispatch(getAnnonceListe2({ type_de_vh: type, user_id: user?.user?.id }));
        break;
      case "camion":
        dispatch(getAnnonceListe3({ type_de_vh: type, user_id: user?.user?.id }));
        break;
      case "moto":
        dispatch(getAnnonceListe4({ type_de_vh: type, user_id: user?.user?.id }));
        break;
      default:
        break;
    }
    
    setLoading(0);
  }, [dispatch, type, user]);

  const handleFilterMax = () => {
    apiClient()
      .get(`/annonce/getpricemax`)
      .then((response) => {
        if (response.data.success) {
          dispatch({ type: FILTER_MAX, payload: response.data.data });
        }
      });
  };

  const fetchData = () => {
    setLoading(1);
    if (!small_prico && !big_prico && !type_de_vh) {
      switch (type) {
        case "camion":
          if (genre_camion_sr?.length !== 0) {
            dispatch(
              getAnnonceListe3({
                type_de_vh: type,
                user_id: user?.user?.id,
                genre_camion: genre_camion_sr,
              })
            );
          } else {
            dispatch(
              getAnnonceListe3({
                type_de_vh: type,
                user_id: user?.user?.id,
              })
            );
          }
          break;
        case "bus":
          if (nbr_plc_sr?.length !== 0) {
            dispatch(
              getAnnonceListe2({
                type_de_vh: type,
                user_id: user?.user?.id,
                nbr_plc_bus: nbr_plc_sr,
              })
            );
          } else {
            dispatch(
              getAnnonceListe2({
                type_de_vh: type,
                user_id: user?.user?.id,
              })
            );
          }
          break;
        case "voiture":
          dispatch(
            getAnnonceListe({ type_de_vh: type, user_id: user?.user?.id })
          );
          break;
        case "moto":
          dispatch(
            getAnnonceListe4({ type_de_vh: type, user_id: user?.user?.id })
          );
          break;

        default:
          break;
      }
    }
    setLoading(0);
  };

  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchQuery(value);
    }, 300),
    []
  );

  const handleSearchChange = useCallback((e) => {
    debouncedSearch(e.target.value);
  }, [debouncedSearch]);

  const handleSortChange = useCallback((option) => {
    setSortOption(option);
  }, []);

  const filteredList = useMemo(() => {
    if (!liste_annonce) return [];
    
    return liste_annonce.filter((item) => {
      const lowerCaseSearchQuery = searchQuery.toLowerCase();
      const lowerCaseTitle = item.title.toLowerCase();
      const lowerCaseMarque = item.marque.toLowerCase();
      const lowerCaseModel = item.model.toLowerCase();

      return (
        lowerCaseTitle.includes(lowerCaseSearchQuery) ||
        lowerCaseMarque.includes(lowerCaseSearchQuery) ||
        lowerCaseModel.includes(lowerCaseSearchQuery)
      );
    });
  }, [liste_annonce, searchQuery]);

  const sortedAndPaginatedList = useMemo(() => {
    let sortedList = [...filteredList];
    
    switch (sortOption) {
      case "Le moins cher":
        sortedList.sort((a, b) => a.price_day - b.price_day);
        break;
      case "Le mieux noté":
        sortedList.sort((a, b) => b.notes - a.notes);
        break;
      default:
        break;
    }

    return sortedList.slice(indexOfFirstItem, indexOfLastItem);
  }, [filteredList, sortOption, indexOfFirstItem, indexOfLastItem]);

  const paginationCount = useMemo(() => {
    const totalCount = filtredListe?.length || liste_annonce?.length || 0;
    return Math.ceil(totalCount / itemsPerPage);
  }, [filtredListe?.length, liste_annonce?.length, itemsPerPage]);

  const isFiltersActive = useMemo(() => {
    // Get values directly from URL params or state
    const minPrice = Number(small_prico) || 0;
    const maxPrice = Number(big_prico) || 0;
    const defaultMaxPrice = 191919191;
    
    const isActive = (
      minPrice > 0 || 
      (maxPrice > 0 && maxPrice !== defaultMaxPrice) || 
      model || 
      vhmarque || 
      boite_de_vitess || 
      nbr_places || 
      chauffeur
    );

    // Debug log
    console.log('Filter State Check:', {
      minPrice,
      maxPrice,
      defaultMaxPrice,
      isActive,
      rawSmallPrice: small_prico,
      rawBigPrice: big_prico
    });

    return isActive;
  }, [small_prico, big_prico, model, vhmarque, boite_de_vitess, nbr_places, chauffeur]);

  useEffect(() => {
    if (isFiltersActive) {
      setLoading(1);
      switch (type) {
        case "voiture":
          const filteredObject = {
            type_de_vh: type,
            user_id: user?.user?.id,
            ...(model && { model }),
            ...(vhmarque && { marque: vhmarque }),
            ...(boite_de_vitess && { boite_de_vitess }),
            ...(nbr_places && { nbr_places }),
            ...(Number(small_prico) > 0 && { price_day_from: Number(small_prico) }),
            ...(Number(big_prico) > 0 && Number(big_prico) !== 191919191 && { price_day_to: Number(big_prico) }),
            ...(chauffeur && { chauffeur }),
            is_filtered: true
          };
          dispatch(getAnnonceListe(filteredObject));
          break;
        // ... other cases
      }
      setLoading(0);
    }
  }, [isFiltersActive, type, small_prico, big_prico]);

  return (
    <>
      {loading == 1 && (
        <Loader
          type="spinner-circle"
          bgColor="#000"
          color="#fff"
          size={100}
          className="absolute-center-loader"
        />
      )}
      <div className="show-all-container">
        <div className="filter-buttons">
          <button className="btn filter-btn" onClick={(e) => handleNav(e)}>
            Filtrer
            <UilFilter size="20" color="white" />
          </button>
          {isFiltersActive && (
            <button className="btn reset-btn" onClick={(e) => handleResetFilters(e)}>
              Réinitialiser
              <UilRefresh size="20" color="white" />
            </button>
          )}
        </div>
        <div class="input-group">
          <span class="input-group-prepend">
            <div class=" search-icon bg-white">
              <UilSearch color="grey" />
            </div>
          </span>
          <input
            className="form-control py-2 border-left-0 border"
            type="search"
            placeholder="Rechercher une annonce ...."
            id="example-search-input"
            onChange={handleSearchChange}
            autoComplete="off"
          />
        </div>
        <div className="filter-item">
          <DropdownButton 
            id="dropdown-basic-button" 
            title={sortOption || "Trier Par"}
          >
            <Dropdown.Item onClick={() => handleSortChange("Le moins cher")}>
              Le moins cher
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleSortChange("Le mieux noté")}>
              Le mieux noté
            </Dropdown.Item>
          </DropdownButton>
        </div>
        <div className="show-items-grid">
          {sortedAndPaginatedList.map((item, index) => (
            <ShowVheic vhei={item} key={index} />
          ))}
        </div>
        {sortedAndPaginatedList.length === 0 && (
          <div className="sorry-div">
            <SearchIcon />
            <span>Aucun résultat trouvé</span>
            <span>
              Désolé, nous n'avons pas trouvé ce que vous cherchez, veuillez
              réessayer...
            </span>
          </div>
        )}

        <div
          className="pagination_section"
          style={{ paddingBottom: "80px", marginTop: "-24px" }}
        >
          <Stack spacing={2} mt={2}>
            <Pagination
              count={paginationCount}
              color="secondary"
              page={currentPage}
              onChange={handleChangePage}
            />
          </Stack>
        </div>
      </div>
    </>
  );
}
