import React from "react";
import "./styles.scss";
import { PurpleMarker } from "../../assets/images";

export default function PositionSwipe({ showdepp, vousou }) {

  const handleshowdepp = () => {
    showdepp(true);
  };
  

  return (
    <div className="position-swipe">
      <div className="position-div">
        <img src={PurpleMarker} alt="" />
        <div className="spans-div">
          <span>Vous êtes ici</span>
          <span className="adress-text">{vousou}</span>
        </div>
      </div>
      <button className="btn " onClick={() => handleshowdepp(showdepp)}>
        Trouver Maintenant
      </button>
    </div>
  );
}
