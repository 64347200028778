import React, { useState, useEffect, useMemo } from "react";
import "./styles.scss";
import { PositionSwipe, RateDrawer, SwipeComp } from "../../components";
import { PurpleMarker } from "../../assets/images";
import { UilAngleLeft } from "@iconscout/react-unicons";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { IS_SIDE_BAR } from "../../constants/actions";
import L from "leaflet";
import PlaceIcon from "../../assets/images/geoplace.png";
import "leaflet-control-geocoder/dist/Control.Geocoder.css";
import { MapContainer, TileLayer, Marker, Popup, useMapEvent } from "react-leaflet";
import axios from "axios";
import { getListDep } from "../../actions/user";
import { GoogleMap, useLoadScript, CircleF, useJsApiLoader, MarkerF  } from "@react-google-maps/api";
import {getGeocode} from "use-places-autocomplete";
import { apiClient } from "../../actions/api";

export default function GetDepPage() {

  const [showdepp, setShowDepp] = useState(false);
  const [userLocation, setUserLocation] = useState({lat: 5.345317, lng: -4.024429});
  const [address, setAddress] = useState("");
  const [gotet, setgotet] = useState(false);
  const { typechoice } = useSelector((state) => state.global)
  const { ratedrawer } = useSelector((state) => state.global);
  const [mapKey, setMapKey] = useState(0);
  const [depannersList, setDepannersList] = useState([]);

  const history = useHistory();
  const dispatch = useDispatch();
  
  const containerStyle = {
    width: '100%',
    height: '100vh'
  };
  
  const [center, setCenter] = useState({
    lat: -3.745,
    lng: -38.523
  });

  useEffect(() => {
    if (userLocation.lat !== 5.345317 && userLocation.lng !== -4.024429) {
      // dispatch(getListDep({ lat: userLocation?.lat, lng: userLocation?.lng }));
      apiClient()
      .post(`/get-users-distance`, { lat: userLocation?.lat, lng: userLocation?.lng }) //change this /membre/login
      .then((res) => {
        if (res.data.success) {
          setDepannersList(res.data.users);
        } 
      });
    } else {
      apiClient()
      .post(`/get-users-distance`, {lat: 5.345317, lng: -4.024429}) //change this /membre/login
      .then((res) => {
        if (res.data.success) {
          setDepannersList(res.data.users);
        } 
      });
    }
  }, []);

  
  useEffect(() => {
      setCenter(userLocation)
  }, [userLocation])

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  })
  
  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    map.setZoom(17); 
    setMap(map);
  }, [center]);
  
  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);


  const handleBack = (e) => {
    e.preventDefault();
    if(showdepp){
      setShowDepp(false);
    } else {
      dispatch({ type: IS_SIDE_BAR, payload: false });
      history.push("/showall/"+typechoice)
    }
  };


useEffect(() => {
  
  const errorCallback = (error) => {
    console.error('Error getting user\'s location:', error.message);
    window.alert('Failed to retrieve your location. Please try again.');
  };

  const checkGeolocationPermission = () => {
    navigator.permissions.query({ name: 'geolocation' })
      .then((permissionStatus) => {
        if (permissionStatus.state === 'granted') {
          getCurrentPosition();
        } else if (permissionStatus.state === 'prompt') {
          navigator.geolocation.getCurrentPosition(getCurrentPosition, errorCallback);
        } else {
          console.error('Location permission denied.');
        }
      })
      .catch((error) => {
        console.error('Error querying location permission:', error);
      });
  };

  checkGeolocationPermission();
}, []);

const getCurrentPosition = () => {
  navigator.geolocation.getCurrentPosition(
    (position) => {
      const { latitude, longitude } = position.coords;
      setUserLocation({ lat: latitude, lng: longitude });
    },
    (error) => {
      console.error("Error getting user's location:", error.message);
      window.alert("Please enable location services to use this feature.");
    }
  );
};

  useEffect(() => {
    if (userLocation !== null) {
      setCenter(userLocation);
      setMapKey((prevKey) => prevKey + 1);
      getGeocode({ location: userLocation })
        .then((results) => {
          if (results && results?.length > 0) {
            setAddress(results[0]?.formatted_address);
          }
        })
        .catch((error) => {
          console.error("Error fetching address:", error.message);
        });
    }
  }, [userLocation]);
  

  return (
    <div className="get-dep-container" id="map">
      <div className="map-google">
            {isLoaded ? (
              <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={17}
              onLoad={onLoad}
              onUnmount={onUnmount}
              key={mapKey}
              options={{
                streetViewControl: false,
                zoomControl: true,
                fullscreenControl: false,
                mapTypeControl: false,
              }}
            >
              <CircleF
                  center={center}
                  radius={100}
                  options={{ fillColor: "#673ab7", strokeColor: "transparent" }}
                />
              <MarkerF position={{lat: Number(center?.lat), lng: Number(center?.lng)}}  />
            </GoogleMap>
            ) : null}
          </div>
      <div className="contentDep">
      {ratedrawer && <RateDrawer />}
      {showdepp ? (
        <>
          {" "}
          <div className="get-dep-head">
            <UilAngleLeft
              size="35"
              color="#6a02c6"
              onClick={(e) => handleBack(e)}
            />
            <div className="position-div-top">
              <img src={PurpleMarker} alt="" />

              <div className="spans-div">
                <span>Vous êtes ici</span>
                <span className="adress-text">{address}</span>
              </div>
            </div>
          </div>
          <SwipeComp depList={depannersList} />
        </>
      ) : (
        <>
          <div className="get-dep-head " id="pos-head">
            <UilAngleLeft
              size="35"
              color="#6a02c6"
              onClick={(e) => handleBack(e)}
            />
          </div>
          <PositionSwipe vousou={address} showdepp={setShowDepp} />
        </>
      )}
      </div>
    </div>
  );
}