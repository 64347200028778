import React, { useEffect, useRef, useState } from "react";
import { UilArrowLeft } from "@iconscout/react-unicons";
import countryList from "react-select-country-list";
import "./styles.scss";
import PhoneInput from "react-phone-input-2";
import { Camera, UserPng } from "../../assets/images";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PICS_BASE_URL } from "../../components/config/keys";
import { apiClient } from "../../actions/api";
import { addtoastmessage } from "../../actions/global";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AUTHENTICATE_USER } from "../../constants/actions";
import { CountrySelect } from "../../components";
import Loader from "react-js-loader";
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';

export default function DeppInfoPage() {
  const { message_toast, typechoice } = useSelector((state) => state.global)
  const { user } = useSelector((state) => state.user);
  const history = useHistory();
  const [ifloading, setIfloading] = useState(0);
  const [userName, setUserName] = useState();
  const [country, setCountry] = useState();
  const [contact, setContact] = useState();
  const [emailUser, setEmailUser] = useState();
  const [passUser, setPassUser] = useState("");
  const [photos, setPhotos] = useState();
  const [error, setError] = useState();
  const [successSt, setSuccess] = useState(null);
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();

  const handleBack = (e) => {
    e.preventDefault();
    history.push("/profiledepp");
  };

  const handleNav = (e) => {
    e.preventDefault();
    history.push("/choosepage");
  };

  const handleClickfile = () => {
    fileInputRef.current.click();
  };
  
  function isImage(filename) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "jpg":
      case "gif":
      case "jpeg":
      case "bmp":
      case "png":
        return true;
      default:
        return false;
    }
  }

  function getExtension(filename) {
    var parts = filename.split(".");
    return parts[parts.length - 1];
  }

  const handleFileChange = (event, t) => {
    const formData = new FormData();
    formData.append("image", event.target.files[0]);

    if (!isImage(event.target.files[0].name)) {
      alert("Veuillez sélectionner une image valide (png,jpg,jpeg,gif..)");
      return false;
    }
    formData.append("typefile", "image");

    setIfloading(1); // make loading

    apiClient()
      .post(`/upload-photos`, formData)
      .then((res) => {
        if (res.data.status === "success") {
          setPhotos(res.data.message);
          setIfloading(0);
        } else {
          setIfloading(0);
          alert("Erreur !");
        }
      });
  };

  useEffect(() => {
    setUserName(user?.user?.service_name);
    setCountry(user?.user?.country);
    setContact({phone : user?.user?.phoneNumber});
    // setPhotos(user?.user?.dep_picture ? (user?.user?.dep_picture?.includes('static') ? UserPng : `${PICS_BASE_URL}/${user?.user?.dep_picture?.replace('//images', '/images')}`) : UserPng);
    setPhotos(user?.user?.profile_picture ? (user?.user?.profile_picture?.includes('static') ? `https://kleeverapp.com/${UserPng}` : `${PICS_BASE_URL}/${user?.user?.profile_picture?.replace('//images', '/images')}`) : `https://kleeverapp.com/${UserPng}`);

  }, [user]);

  const handleSubmitProfil = async (e) => {
    e.preventDefault();

    if (!userName) {
        setError('Veuillez saisir un nom');
        return;
    }

    if (!contact?.phone) {
        setError('Veuillez saisir un numéro de téléphone');
        return;
    }

    if (passUser !== '' && passUser.length < 8) {
        setError('Le mot de passe doit comporter au moins 8 caractères');
        return;
    }

    try {
        let formData = {
            'userId': user?.user?.id,
            'service_name': userName,
            'phoneNumber': contact?.phone,
            'country': country,
            'password': passUser,
            'dep_picture': photos
        };

        const response = await apiClient().post('/complete-dep-profile', formData);

        if (response.data.success) {
            dispatch({ type: AUTHENTICATE_USER, payload: response.data });
            dispatch(addtoastmessage('Votre profil a été mis à jour avec succès'));
        } else {
            setError('Une erreur s\'est produite lors de la mise à jour du profil');
        }
    } catch (error) {
        setError('Une erreur s\'est produite lors de la mise à jour du profil');
    }
};


  useEffect(() => {
    if(error){
      toast.error(error)
      setError(null);
    }
  }, [error]);

  useEffect(() => {
    if(message_toast !== ''){
        toast.success(message_toast)
        dispatch(addtoastmessage(''));
        // history.push("/showall/"+typechoice)
    }
  }, [message_toast]);


  return (
    <>
    {ifloading === 1 && (
        <Loader
          type="spinner-circle"
          bgColor="#000"
          color="#fff"
          size={100}
          className="absolute-center-loader"
        />
      )}
    <ToastContainer />
      <div className="depp-info-page">
        <div className="profile-depp-head">
          <div className="kleever-log" onClick={(e) => handleNav(e)}></div>
        </div>
        <div className="data-div">
          <div className="data-head">
            <div className="left-side">
              <div className="back-control" onClick={(e) => handleBack(e)}>
                <UilArrowLeft size="30" color="black" />
                <span>Retour</span>
              </div>
            </div>
            <div className="right-side">
              <div className="profile-pic-div" style={{
                  background: `url('${photos?.includes('kleeverapp.com') ? photos : PICS_BASE_URL+'/'+photos}')`,
                }}>
                {/* <img src={Camera} alt="" onClick={() => handleClickfile()} /> */}
                <PhotoCameraIcon onClick={() => handleClickfile()} />
                <form style={{ padding: "0px" }}>
              <input
                style={{ display: "none" }}
                type="file"
                onChange={(e) => handleFileChange(e)}
                ref={fileInputRef}
              />
            </form>
              </div>
            </div>
          </div>
          <div className="data-body">
            <div className="data-body-item">
              <span>Nom de votre service</span>
              <input type="text" defaultValue={userName} onChange={(e) => setUserName(e.target.value)} />
            </div>
            <div className="data-body-item">
              <span>Lieu de résidence</span>
              {/* <input type="text" defaultValue={country} onChange={(e) => setCountry(e.target.value)}/> */}
            <CountrySelect selected={setCountry} country={country} fromProf={true} />

            </div>
            <div className="data-body-item">
              <span>Contact</span>
              <PhoneInput className="phone-picker" country={"ci"} value={contact?.phone}
              onChange={phone => setContact({ phone })} autoFormat={false}/>
            </div>
            <div className="data-body-item">
              <span>Mot de passe</span>
              <input type="password" onChange={(e) => setPassUser(e.target.value)}
              value={passUser}/>
            </div>
          </div>
          <button className="btn save-btn" onClick={(e) => handleSubmitProfil(e)}>Enregistrer</button>
        </div>
      </div>
    </>
  );
}
