import React, { useEffect, useState } from 'react'
import './styles.scss' ; 
import { NavHeader } from '../../components';
import { useHistory } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { loginUser } from '../../actions/user';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IS_SIDE_BAR } from '../../constants/actions';
import { apiClient } from '../../actions/api';


export default function SignInPage() {
  const history = useHistory();
  const [emailOrPhone, setEmail] = useState() ; 
  const [pass, setPass] = useState() ; 
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const handleLogin = (e)=> { 
    e.preventDefault();
      if (!emailOrPhone || !pass) {
        setError("Veulliez saisir un login et un mot de passe"); 
        return false;
      }
      dispatch(loginUser({
        login: emailOrPhone,
        password: pass,
      }))
      .then(() => {
        dispatch({ type: IS_SIDE_BAR, payload: false });
        history.push('/choosepage'); 
      })
      .catch((errors) => {
        setError(errors.message && "L'email ou le mot de passe est incorrect");
      });
  }

  useEffect(() => {
    if(error){
      toast.error(error)
      setError(null);
    }
  }, [error])
  

  return (
    <div className='sign-in-container'>
      <NavHeader/>
      <h3>Connexion</h3>
      <form className='login-form' onSubmit={handleLogin}>
        <div className='sign-in-body'>
        <div className='input-part'>
          <p>Votre adresse e-mail ou numéro de téléphone</p>
          <input type='text' value={emailOrPhone} onChange={(e)=>setEmail(e.target.value)} /> 
        </div>
        <div className='input-part'>
          <p>Mot de passe</p>
          <input type='password' value={pass} onChange={(e)=>setPass(e.target.value)}/> 
        </div>
        <div className='forget-pass' onClick={() => history.push('/forget-pass') } >
          Mot de passe oublié
        </div>
      </div>
      <button className='btn cnx-btn' type='submit'>
        Se connecter 
      </button>
      </form>
    <ToastContainer />
    </div>
  )
}  