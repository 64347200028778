import React, { useState, useEffect } from 'react';
import { UilBell } from "@iconscout/react-unicons";
import './styles.scss';
import { useSelector, useDispatch } from 'react-redux';
import { markNotificationRead, markAllNotificationsRead, fetchInitialNotifications, fetchUnreadCount, FETCH_NOTIFICATIONS_SUCCESS } from '../../actions/notifications';
import { apiClient  } from '../../actions/api';
import { BASE_URL } from '../../components/config/keys';

const NotificationComponent = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const notifications = useSelector(state => state.notifications?.items);
  const unreadCount = useSelector(state => state.notifications?.unreadCount);
  const user = useSelector(state => state.user.user.user);

 

  useEffect(() => {
    console.log("user notifications",notifications);
    if (user?.id) {
      if (notifications.length === 0) {
        dispatch(fetchInitialNotifications(user.id));
      }
      dispatch(fetchUnreadCount(user.id));
    }
  }, [dispatch, user?.id]);

  const handleMarkAsRead = async (notificationId) => {
    console.log("notificationId",notificationId);
    try {
      const response = await apiClient().post(`${BASE_URL}/notifications/${user.id}/${notificationId}/read`);
      console.log("response mark as read",response);
      dispatch(markNotificationRead(notificationId));
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  const handleMarkAllAsRead = async () => {
    try {
      await apiClient().post(`${BASE_URL}/notifications/mark-all-read`, {
        userId: user.id
      });
      dispatch(markAllNotificationsRead());
    } catch (error) {
      console.error('Error marking all notifications as read:', error);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isOpen && !event.target.closest('.notification-container')) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isOpen]);

  return (
    <>
    {user?.id && (
    <div className="notification-container">
      <div className="notification-icon" onClick={() => setIsOpen(!isOpen)}>
        <UilBell size="30" color="white" />
        {unreadCount > 0 && <span className="notification-badge">{unreadCount}</span>}
      </div>
      
      {isOpen && (
        <div className="notification-dropdown">
          <div className="notification-header">
            <h3>Notifications</h3>
            {unreadCount > 0 && (
              <button className="mark-all-read" onClick={handleMarkAllAsRead}>
                Tout marquer comme lu
              </button>
            )}
          </div>
          
          <div className="notification-list">
            {notifications.map((notification) => (
              <div 
                key={notification.id} 
                className={`notification-item ${!notification.read ? 'unread' : ''}`}
                onClick={() => handleMarkAsRead(notification.id)}
              >
                <div className="notification-content">
                  <h4>{notification.title}</h4>
                  <p>{notification.body}</p>
                  <span className="notification-time">
                    {new Date(notification.created_at).toLocaleString('fr-FR', {
                      year: 'numeric',
                      month: 'long',    
                      day: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit'
                    })}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
        )}
      </div>
    )}
    </>
  );
};

export default NotificationComponent; 