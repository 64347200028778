import { 
    MARK_NOTIFICATION_READ, 
    MARK_ALL_NOTIFICATIONS_READ,
    ADD_FCM_NOTIFICATION,
    FETCH_NOTIFICATIONS_SUCCESS,
    SET_UNREAD_COUNT
  } from '../constants/actions';
  
  const initialState = {
    items: [],
    unreadCount: 0
  };
  
  export default function notificationsReducer(state = initialState, action) {
    switch (action.type) {
      case FETCH_NOTIFICATIONS_SUCCESS:
        return {
          ...state,
          items: action.payload
        };
  
      case ADD_FCM_NOTIFICATION:
        return {
          ...state,
          items: [action.payload, ...state.items],
          unreadCount: state.unreadCount + 1
        };
  
      case MARK_NOTIFICATION_READ:
        return {
          ...state,
          items: state.items.map(notification =>
            notification.id === action.payload
              ? { ...notification, read: true }
              : notification
          ),
          unreadCount: Math.max(0, state.unreadCount - 1)
        };
  
      case MARK_ALL_NOTIFICATIONS_READ:
        return {
          ...state,
          items: state.items.map(notification => ({ ...notification, read: true })),
          unreadCount: 0
        };
  
      case SET_UNREAD_COUNT:
        return {
          ...state,
          unreadCount: action.payload
        };
  
      default:
        return state;
    }
  }