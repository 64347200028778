import React, { useEffect, useState } from "react";
import "./styles.scss";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { genreCamion, nbrPlaceBus } from "../../actions/global";

export default function SelectVhPopup() {
  const { vhtype } = useParams();
  const { user } = useSelector((state) => state.user);
  const history = useHistory();

  const [checkedItems, setCheckedItems] = useState({
    eightToFifteen: false,
    fifteenToTwenty: false,
    twentyToThirty: false,
    overThirty: false,
  });

  const [truckTypes, setTruckTypes] = useState({
    demenagement: false,
    marchandises: false,
    benne: false,
    grue: false,
    plateau: false,
    autres: false,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const selectedTruckTypes = Object.keys(truckTypes).filter(key => truckTypes[key]);
    dispatch(genreCamion(selectedTruckTypes));
  }, [truckTypes]);

  useEffect(() => {
    const selectedBusPlaces = Object.keys(checkedItems).filter(key => checkedItems[key]);
    dispatch(nbrPlaceBus(selectedBusPlaces));
  }, [checkedItems]);

  const handleCheckboxChange = (key) => {
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [key]: !prevCheckedItems[key],
    }));
  };

  const handleCheckboxChange2 = (key) => {
    setTruckTypes((prevTruckTypes) => ({
      ...prevTruckTypes,
      [key]: !prevTruckTypes[key],
    }));
  };

  const handleNav = (vhtype) => {
    history.push("/showall/" + vhtype);
  };

  const handlevhtype = (vhtype) => {
    switch (vhtype) {
      case "bus":
        return (
          <>
            <h3>
              Nombre de places
              <div className="line"></div>
            </h3>
            <div className="select-div">
              <div className="select-element">
                <p>Entre 8 et 15 places</p>
                <input
                  type="checkbox"
                  checked={checkedItems.eightToFifteen}
                  onChange={() => handleCheckboxChange("eightToFifteen")}
                />
              </div>
              <div className="select-element">
                <p>Entre 15 et 20 places</p>
                <input
                  type="checkbox"
                  checked={checkedItems.fifteenToTwenty}
                  onChange={() => handleCheckboxChange("fifteenToTwenty")}
                />
              </div>
              <div className="select-element">
                <p>Entre 20 et 30 places</p>
                <input
                  type="checkbox"
                  checked={checkedItems.twentyToThirty}
                  onChange={() => handleCheckboxChange("twentyToThirty")}
                />
              </div>
              <div className="select-element">
                <p>plus de 30 places</p>
                <input
                  type="checkbox"
                  checked={checkedItems.overThirty}
                  onChange={() => handleCheckboxChange("overThirty")}
                />
              </div>
            </div>
            <button className="btn ok-btn" onClick={() => handleNav("bus")}>
              Confirmer
            </button>
          </>
        );
      case "camion":
        return (
          <>
            <h3>
              Choisissez le modèle de camion
              <br />
              <div className="line"></div>
            </h3>
            <div className="select-div camion-div">
              <span>Possibilité de cocher plusieurs modèles</span>
              <div className="select-element">
                <input
                  type="checkbox"
                  checked={truckTypes.demenagement}
                  onChange={() => handleCheckboxChange2("demenagement")}
                />
                <p>Camion de déménagement</p>
              </div>
              <div className="select-element">
                <input
                  type="checkbox"
                  checked={truckTypes.marchandises}
                  onChange={() => handleCheckboxChange2("marchandises")}
                />
                <p>Camion de marchandises</p>
              </div>
              <div className="select-element">
                <input
                  type="checkbox"
                  checked={truckTypes.benne}
                  onChange={() => handleCheckboxChange2("benne")}
                />
                <p>Camion benne</p>
              </div>
              <div className="select-element">
                <input
                  type="checkbox"
                  checked={truckTypes.grue}
                  onChange={() => handleCheckboxChange2("grue")}
                />
                <p>Camion grue</p>
              </div>
              <div className="select-element">
                <input
                  type="checkbox"
                  checked={truckTypes.plateau}
                  onChange={() => handleCheckboxChange2("plateau")}
                />
                <p>Camion plateau</p>
              </div>
              <div className="select-element">
                <input
                  type="checkbox"
                  checked={truckTypes.autres}
                  onChange={() => handleCheckboxChange2("autres")}
                />
                <p>Autres</p>
              </div>
            </div>
            <button className="btn ok-btn" onClick={() => handleNav("camion")}>
              Confirmer
            </button>
          </>
        );
    }
  };

  return (
    <div className="select-container">
      {handlevhtype(vhtype)}
      <div className="bottom-g"></div>
    </div>
  );
}
